<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-login">
    <div class="vx-col sm:w-1/4 md:w-1/4 lg:w-2/4 xl:w-2/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">

          <div class="vx-row no-gutter justify-center items-center">


            <div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">
              <div class="p-8 login-tabs-container">

                <div class="vx-card__title mb-4">
                  <h4 class="mb-4">Login</h4>
                  <p>Welcome back, please login to your account.</p>
                </div>

                <div>
                  <vs-input name="username" icon-no-border icon="icon icon-user" icon-pack="feather"
                    label-placeholder="Username" v-model="username" @keyup.enter="login" class="w-full" />

                  <vs-input type="password" name="password" icon-no-border icon="icon icon-lock" icon-pack="feather"
                    label-placeholder="Password" v-model="password" @keyup.enter="login" class="w-full mt-6" />
                  <br />

                  <div class="flex vx-col sm:w-2/2 md:w-2/2 lg:w-2/2">
                    <vs-input type="token" name="token" icon-no-border icon="icon icon-lock" icon-pack="feather"
                      label-placeholder="OTP" autocomplete="one-time-code" v-model="token" @keyup.enter="login"
                      class="w-full mt-6" />
                    <vs-button class="w-full mt-6 sm:w-1/2 md:w-1/2 lg:w-1/2" @click="onPaste">paste</vs-button>
                  </div>

                  <br />

                  <vs-button class="w-full" @click="login">Login</vs-button>
                  <br>

                </div>

              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      username: '',
      password: '',
      token: ''
    }
  },
  methods: {
    async login () {
      this.$vs.loading()
      const {
        username,
        password,
        token
      } = this
      const {
        dispatch
      } = this.$store
      dispatch('auth/login', {
        username,
        password,
        token
      }).then(() => {
        this.$vs.loading.close()
      })
        .catch(error => {
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Error',
            text: error.message,
            position: 'top-right',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        })
    },
    async onPaste () {
      try {
        if ((await navigator.clipboard.readText()).length <= 6) {
          this.token = await navigator.clipboard.readText()
        } else {
          this.$vs.notify({
            title: 'Error',
            text: 'ข้อความคุณมีตัวอักษรมากกว่า 6 ตัว',
            position: 'top-right',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      } catch (error) {
        this.$vs.notify({
          title: 'Error',
          text: 'ไม่สามารถวางได้',
          position: 'top-right',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }
    }
  }
}

</script>

<style lang="scss">
#page-login {
  .social-login-buttons {
    .bg-facebook {
      background-color: #1551b1
    }

    .bg-twitter {
      background-color: #00aaff
    }

    .bg-google {
      background-color: #4285F4
    }

    .bg-github {
      background-color: #333
    }
  }
}
</style>
